import React from "react"
import styled from "styled-components"

const IndexPage = () => (
  <Container>
    <Circle>
      <H1>CHIALAT</H1>
      <H2>
        <span>⭑</span>
        <span>⭑</span>
        <span>⭑</span>
        <span>⭑</span>
        <span>⭑</span>
      </H2>
    </Circle>
  </Container>
)

export default IndexPage

const Container = styled("div")`
  height: 100vh;
  width: 100vw;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Rozha One;
`
const Circle = styled("main")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ef3340;
  height: 400px;
  width: 400px;
  border-radius: 240px;
  border: double 20px white;
`
const H1 = styled("h1")`
  font-weight: normal;
  font-size: 4rem;
  font-style: italic;
  color: white;
  margin: 0px;
  letter-spacing: 0.3rem;
`
const H2 = styled(H1)`
  font-size: 3rem;
  line-height: 1;
  margin: 0px;
  font-style: normal;
  span {
    margin: 0px 0.1rem;
  }
`
